const redirections = [
  {
    from: /^\/([^/]*\/)?bigdata(\/[^/]*)?$/,
    to: (match) => {
      return `${match[1] || ""}data${match[2] || ""}`;
    },
  },
];

export default redirections;
