import React from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import App from "./containers/App";
import Offers from "./containers/Offers";
import Offer from "./containers/Offer";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import PageNotFound from "./containers/PageNotFound";
import SendCVSuccessPage from "./containers/SendCVSuccessPage";
import ThankYouPage from "./containers/ThankYou";
import RegexRedirect from "./components/RegexRedirect/RegexRedirect";
import configureStore from "./store/configureStore";

// polyfills
import "core-js/es6/object";
import "core-js/es6/promise";
import "core-js/es6/array";
import "core-js/es6/symbol";

const store = configureStore();

const rootContainer = document.getElementById("root");
const root = createRoot(rootContainer);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <RegexRedirect />
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Offers />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="thank-you" element={<SendCVSuccessPage />} />
            <Route path="stay-with-us/:hash" element={<ThankYouPage />} />
            <Route path="404" element={<PageNotFound />} />
            <Route path=":city/:technology/:seoSlug" element={<Offer />} />
            <Route path=":cityOrTechnology" element={<Offers />} />
            <Route path=":city/:technology" element={<Offers />} />
            <Route path="*" element={<Navigate to="404" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);
