import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import redirections from "../../redirections";

const RegexRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;

    redirections.forEach(({ from, to }) => {
      const regex = new RegExp(from);
      const match = path.match(regex);

      if (match) {
        navigate(to(match), { replace: true });
      }
    });
  }, [location, navigate]);

  return null;
};

export default RegexRedirect;
